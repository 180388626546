import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/travis/build/reach/reach-ui/website/src/components/mdx-layout.js";
import SEO from "../components/SEO";
import { TOC, TOCList, TOCLink } from "../components/TOC";
import { Pipe } from "../components/Pipe";
import { Note } from "../components/Note";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Slider (range)" description="Accessible slider component for React" mdxType="SEO" />
    <h1 {...{
      "id": "slider-range"
    }}>{`Slider (Range)`}</h1>
    <TOC mdxType="TOC">
  <TOCList mdxType="TOCList">
    <TOCLink href="#slider" mdxType="TOCLink">Slider</TOCLink>
    <TOCLink href="#sliderinput" mdxType="TOCLink">SliderInput</TOCLink>
    <TOCLink href="#slidertrack" mdxType="TOCLink">SliderTrack</TOCLink>
    <TOCLink href="#sliderrange" mdxType="TOCLink">SliderRange</TOCLink>
    <TOCLink href="#slidermarker" mdxType="TOCLink">SliderMarker</TOCLink>
    <TOCLink href="#sliderhandle" mdxType="TOCLink">SliderHandle</TOCLink>
  </TOCList>
    </TOC>
    <ul>
      <li parentName="ul">{`Source: `}<a parentName="li" {...{
          "href": "https://github.com/reach/reach-ui/tree/main/packages/slider"
        }}>{`https://github.com/reach/reach-ui/tree/main/packages/slider`}</a></li>
      <li parentName="ul">{`WAI-ARIA: `}<a parentName="li" {...{
          "href": "https://www.w3.org/TR/wai-aria-practices-1.2/#slider"
        }}>{`https://www.w3.org/TR/wai-aria-practices-1.2/#slider`}</a></li>
    </ul>
    <p>{`A UI input component where the user selects a value from within a given range. A Slider has a handle that can be moved along a track to change its value. When the user's mouse or focus is on the Slider's handle, the value can be incremented with keyboard controls.`}</p>
    <p>{`Sliders are composed by an outer `}<inlineCode parentName="p">{`Slider`}</inlineCode>{` component with a `}<inlineCode parentName="p">{`SliderTrack`}</inlineCode>{` child component. `}<inlineCode parentName="p">{`SliderTrack`}</inlineCode>{` can accept `}<inlineCode parentName="p">{`SliderRange`}</inlineCode>{`, `}<inlineCode parentName="p">{`SliderMarker`}</inlineCode>{` and `}<inlineCode parentName="p">{`SliderHandle`}</inlineCode>{` components as children.`}</p>
    <p>{`You can use `}<inlineCode parentName="p">{`Slider`}</inlineCode>{` as a simple standalone component, or compose its parts with `}<inlineCode parentName="p">{`SliderInput`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
function Example() {
  return <Slider />;
}
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
function Example() {
  return (
    <div>
      <SliderInput>
        <SliderTrack>
          <SliderRange />
          <SliderMarker value={50} />
          <SliderHandle />
        </SliderTrack>
      </SliderInput>
    </div>
  );
}
`}</code></pre>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <p>{`From the command line in your project directory, run `}<inlineCode parentName="p">{`npm install @reach/slider`}</inlineCode>{` or `}<inlineCode parentName="p">{`yarn add @reach/slider`}</inlineCode>{`. Then import the components and styles that you need:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install @reach/slider
# or
yarn add @reach/slider
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import {
  Slider,
  SliderInput,
  SliderTrack,
  SliderRange,
  SliderHandle,
  SliderMarker,
} from "@reach/slider";
import "@reach/slider/styles.css";
`}</code></pre>
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// Basic slider
function Example() {
  return <Slider min={0} max={200} step={10} />;
}

// Composed slider components
function ExampleComposed() {
  return (
    <SliderInput min={0} max={200} step={10}>
      <SliderTrack>
        <SliderRange />
        <SliderHandle />
      </SliderTrack>
    </SliderInput>
  );
}
`}</code></pre>
    <h2 {...{
      "id": "slider-events"
    }}>{`Slider Events`}</h2>
    <p>{`Prior to v0.10.0, Slider used pointer events for unified event handling across different pointer types (mouse, touch, stylus, etc.). While we still use `}<inlineCode parentName="p">{`onPointerUp`}</inlineCode>{` and `}<inlineCode parentName="p">{`onPointerDown`}</inlineCode>{` to take advantage of `}<inlineCode parentName="p">{`setPointerCapture`}</inlineCode>{` and `}<inlineCode parentName="p">{`releasePointerCapture`}</inlineCode>{`, we have moved all other logic to `}<inlineCode parentName="p">{`onMouseDown`}</inlineCode>{`, `}<inlineCode parentName="p">{`onTouchStart`}</inlineCode>{`, `}<inlineCode parentName="p">{`onMouseUp`}</inlineCode>{` and `}<inlineCode parentName="p">{`onTouchEnd`}</inlineCode>{` to prevent the need for a polyfill.`}</p>
    <p>{`Another note on events: because the slider move and end events fire on the document (and because of `}<a parentName="p" {...{
        "href": "https://github.com/facebook/react/issues/9809#issuecomment-413978405"
      }}>{`some quirks in Chrome related to `}<inlineCode parentName="a">{`onTouchStart`}</inlineCode></a>{`), the following event props on `}<inlineCode parentName="p">{`Slider`}</inlineCode>{` and `}<inlineCode parentName="p">{`SliderInput`}</inlineCode>{` use native DOM events rather than React's synthetic events in their respective handlers.`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`onPointerDown`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`onPointerUp`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`onMouseDown`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`onMouseMove`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`onMouseUp`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`onTouchStart`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`onTouchMove`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`onTouchEnd`}</inlineCode></li>
    </ul>
    <h2 {...{
      "id": "component-api"
    }}>{`Component API`}</h2>
    <h3 {...{
      "id": "slider"
    }}>{`Slider`}</h3>
    <p>{`High-level component to render a slider in its simplest form. This works simililarly to a native HTML range input.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Slider min={0} max={100} />
`}</code></pre>
    <h4 {...{
      "id": "slider-css-selectors"
    }}>{`Slider CSS Selectors`}</h4>
    <p>{`Please see the `}<a parentName="p" {...{
        "href": "/styling"
      }}>{`styling guide`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`[data-reach-slider] {
}
[data-reach-slider][data-disabled] {
}
[data-reach-slider][data-orientation="(horizontal|vertical)"] {
}
`}</code></pre>
    <h4 {...{
      "id": "slider-props"
    }}>{`Slider Props`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Prop`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#slider-children"
            }}><inlineCode parentName="a">{`children`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`true`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#slider-defaultvalue"
            }}><inlineCode parentName="a">{`defaultValue`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#slider-disabled"
            }}><inlineCode parentName="a">{`disabled`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#slider-getarialabel"
            }}><inlineCode parentName="a">{`getAriaLabel`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`func`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#slider-getariavaluetext"
            }}><inlineCode parentName="a">{`getAriaValueText`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`func`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#slider-handlealignment"
            }}><inlineCode parentName="a">{`handleAlignment`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"center"`}</inlineCode>{` `}<Pipe mdxType="Pipe" />{` `}<inlineCode parentName="td">{`"contain"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"center"`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#slider-max"
            }}><inlineCode parentName="a">{`max`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`100`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#slider-min"
            }}><inlineCode parentName="a">{`min`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`0`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#slider-name"
            }}><inlineCode parentName="a">{`name`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#slider-onchange"
            }}><inlineCode parentName="a">{`onChange`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`func`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#slider-orientation"
            }}><inlineCode parentName="a">{`orientation`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"horizontal"`}</inlineCode>{` `}<Pipe mdxType="Pipe" />{` `}<inlineCode parentName="td">{`"vertical"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"horizontal"`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#slider-step"
            }}><inlineCode parentName="a">{`step`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`1`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#slider-value"
            }}><inlineCode parentName="a">{`value`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <h5 {...{
      "id": "slider-children"
    }}>{`Slider children`}</h5>
    <p><inlineCode parentName="p">{`children?: React.ReactNode`}</inlineCode></p>
    <p><inlineCode parentName="p">{`Slider`}</inlineCode>{` can accept `}<inlineCode parentName="p">{`SliderMarker`}</inlineCode>{` children to enhance display of specific values along the track.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Slider min={0} max={100}>
  {new Array(11).fill("x").map((x, index) => (
    <SliderMarker value={index * 10}>
      <span>{index * 10}</span>
    </SliderMarker>
  ))}
</Slider>
`}</code></pre>
    <h5 {...{
      "id": "slider-defaultvalue"
    }}>{`Slider defaultValue`}</h5>
    <p><inlineCode parentName="p">{`defaultValue?: number`}</inlineCode></p>
    <p>{`The defaultValue is used to set an initial value for an uncontrolled Slider.`}</p>
    <h5 {...{
      "id": "slider-disabled"
    }}>{`Slider disabled`}</h5>
    <p><inlineCode parentName="p">{`disabled?: boolean`}</inlineCode></p>
    <p>{`Whether or not the slider should be disabled from user interaction.`}</p>
    <h5 {...{
      "id": "slider-getarialabel"
    }}>{`Slider getAriaLabel`}</h5>
    <p><inlineCode parentName="p">{`getAriaLabel?(value: number): string`}</inlineCode></p>
    <p>{`A function used to set a human-readable name for the slider.`}</p>
    <h5 {...{
      "id": "slider-getariavaluetext"
    }}>{`Slider getAriaValueText`}</h5>
    <p><inlineCode parentName="p">{`getAriaValueText?(value: number): string`}</inlineCode></p>
    <p>{`A function used to set a human-readable value text based on the slider's current value.`}</p>
    <h5 {...{
      "id": "slider-handlealignment"
    }}>{`Slider handleAlignment`}</h5>
    <p><inlineCode parentName="p">{`handleAlignment?: "center" | "contain"`}</inlineCode></p>
    <p>{`When set to `}<inlineCode parentName="p">{`center`}</inlineCode>{`, the slider's handle will be positioned directly centered over the slider's curremt value on the track. This means that when the slider is at its min or max value, a visiable slider handle will extend beyond the width (or height in vertical mode) of the slider track. When set to `}<inlineCode parentName="p">{`contain`}</inlineCode>{`, the slider handle will always be contained within the bounds of the track, meaning its position will be slightly offset from the actual value depending on where it sits on the track.`}</p>
    <h5 {...{
      "id": "slider-max"
    }}>{`Slider max`}</h5>
    <p><inlineCode parentName="p">{`max?: number`}</inlineCode></p>
    <p>{`The maximum value of the slider. Defaults to `}<inlineCode parentName="p">{`100`}</inlineCode>{`.`}</p>
    <h5 {...{
      "id": "slider-min"
    }}>{`Slider min`}</h5>
    <p><inlineCode parentName="p">{`min?: number`}</inlineCode></p>
    <p>{`The minimum value of the slider. Defaults to `}<inlineCode parentName="p">{`0`}</inlineCode>{`.`}</p>
    <h5 {...{
      "id": "slider-name"
    }}>{`Slider name`}</h5>
    <p><inlineCode parentName="p">{`name?: string`}</inlineCode></p>
    <p>{`If the slider is used as a form input, it should accept a `}<inlineCode parentName="p">{`name`}</inlineCode>{` prop to identify its value in context of the form.`}</p>
    <h5 {...{
      "id": "slider-onchange"
    }}>{`Slider onChange`}</h5>
    <p><inlineCode parentName="p">{`onChange?(newValue: number, props?: { min?: number, max?: number, handlePosition?: string }): void`}</inlineCode></p>
    <p>{`Callback that fires when the slider value changes. When the `}<inlineCode parentName="p">{`value`}</inlineCode>{` prop is set, the Slider state becomes controlled and `}<inlineCode parentName="p">{`onChange`}</inlineCode>{` must be used to update the value in response to user interaction.`}</p>
    <h5 {...{
      "id": "slider-orientation"
    }}>{`Slider orientation`}</h5>
    <p><inlineCode parentName="p">{`orientation?: "horizontal" | "vertical"`}</inlineCode></p>
    <p>{`Sets the slider to horizontal or vertical mode.`}</p>
    <h5 {...{
      "id": "slider-step"
    }}>{`Slider step`}</h5>
    <p><inlineCode parentName="p">{`step?: number`}</inlineCode></p>
    <p>{`The step attribute is a number that specifies the granularity that the value must adhere to as it changes. Step sets minimum intervals of change, creating a "snap" effect when the handle is moved along the track.`}</p>
    <h5 {...{
      "id": "slider-value"
    }}>{`Slider value`}</h5>
    <p><inlineCode parentName="p">{`value?: number`}</inlineCode></p>
    <p>{`An explicit value for the slider. When this prop is used, the Slider state becomes controlled and `}<inlineCode parentName="p">{`onChange`}</inlineCode>{` must be used to update the value in response to user interaction.`}</p>
    <h3 {...{
      "id": "sliderinput"
    }}>{`SliderInput`}</h3>
    <p>{`The parent component of the slider interface. This is a lower level component if you need more control over styles or rendering the slider's inner components.`}</p>
    <h4 {...{
      "id": "sliderinput-css-selectors"
    }}>{`SliderInput CSS Selectors`}</h4>
    <p>{`See selectors for `}<a parentName="p" {...{
        "href": "#slider-css-selectors"
      }}><inlineCode parentName="a">{`Slider`}</inlineCode></a>{`.`}</p>
    <h4 {...{
      "id": "sliderinput-props"
    }}>{`SliderInput Props`}</h4>
    <p><inlineCode parentName="p">{`SliderInput`}</inlineCode>{` accepts the same props as `}<a parentName="p" {...{
        "href": "#slider-props"
      }}><inlineCode parentName="a">{`Slider`}</inlineCode></a>{`.`}</p>
    <h5 {...{
      "id": "sliderinput-children"
    }}>{`SliderInput children`}</h5>
    <p><inlineCode parentName="p">{`children: React.ReactNode | ((props: { hasFocus?: boolean, id?: string | undefined, sliderId?: string | undefined, max?: number, min?: number, value?: number, valueText?: string | undefined }) => JSX.Element)`}</inlineCode></p>
    <p>{`Slider expects `}<inlineCode parentName="p">{`<SliderTrack>`}</inlineCode>{` as its child; The track will accept all additional slider sub-components as children. It can also accept a function/render prop as its child to expose some of its internal state variables.`}</p>
    <p><inlineCode parentName="p">{`SliderArgs = { hasFocus: boolean, id: string, max: number, min: number, value: number, valueText: string }`}</inlineCode></p>
    <h3 {...{
      "id": "slidertrack"
    }}>{`SliderTrack`}</h3>
    <p>{`The parent component of the slider's inner components. Represents the visual track on which the slider's handle moves to represent its value.`}</p>
    <h4 {...{
      "id": "slidertrack-css-selectors"
    }}>{`SliderTrack CSS Selectors`}</h4>
    <p>{`Please see the `}<a parentName="p" {...{
        "href": "/styling"
      }}>{`styling guide`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`[data-reach-slider-track] {
}
[data-reach-slider-track][data-disabled] {
}
[data-reach-slider-track][data-orientation="(horizontal|vertical)"] {
}
`}</code></pre>
    <h4 {...{
      "id": "slidertrack-props"
    }}>{`SliderTrack Props`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Prop`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#slidertrack-children"
            }}><inlineCode parentName="a">{`children`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`true`}</td>
        </tr>
      </tbody>
    </table>
    <h5 {...{
      "id": "slidertrack-children"
    }}>{`SliderTrack children`}</h5>
    <p><inlineCode parentName="p">{`children: React.ReactNode`}</inlineCode></p>
    <p><inlineCode parentName="p">{`SliderTrack`}</inlineCode>{` expects `}<inlineCode parentName="p">{`<SliderHandle>`}</inlineCode>{`, at minimum, for the Slider to function. All other Slider subcomponents should be passed as children inside the `}<inlineCode parentName="p">{`SliderTrack`}</inlineCode>{`.`}</p>
    <h3 {...{
      "id": "sliderrange"
    }}>{`SliderRange`}</h3>
    <p>{`The (typically) highlighted portion of the track that represents the space between the slider's `}<inlineCode parentName="p">{`min`}</inlineCode>{` value and its current value.`}</p>
    <Note mdxType="Note">
  <p>
    <strong>NOTE:</strong> This component was named{" "}
    <code>SliderTrackHighlight</code> in a previous version of Reach UI. That
    component will be dropped in a future version. We recommend updating your
    projects to replace <code>SliderTrackHighlight</code> with{" "}
    <code>SliderRange</code>.
  </p>
    </Note>
    <h4 {...{
      "id": "sliderrange-css-selectors"
    }}>{`SliderRange CSS Selectors`}</h4>
    <p>{`Please see the `}<a parentName="p" {...{
        "href": "/styling"
      }}>{`styling guide`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`[data-reach-slider-range] {
}
[data-reach-slider-range][data-disabled] {
}
[data-reach-slider-range][data-orientation="(horizontal|vertical)"] {
}
`}</code></pre>
    <h3 {...{
      "id": "slidermarker"
    }}>{`SliderMarker`}</h3>
    <p>{`A fixed value marker. These can be used to illustrate a range of steps or highlight important points along the slider track.`}</p>
    <h4 {...{
      "id": "slidermarker-css-selectors"
    }}>{`SliderMarker CSS Selectors`}</h4>
    <p>{`Please see the `}<a parentName="p" {...{
        "href": "/styling"
      }}>{`styling guide`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`[data-reach-slider-marker] {
}
[data-reach-slider-marker][data-disabled] {
}
[data-reach-slider-marker][data-orientation="(horizontal|vertical)"] {
}
[data-reach-slider-marker][data-state="(under|at|over)-value"] {
  /* marker is under, at, or over the slider value */
}
[data-reach-slider-marker][data-value="VALUE_REF"] {
  /* marker is under, at, or over the slider value */
}
`}</code></pre>
    <h4 {...{
      "id": "slidermarker-props"
    }}>{`SliderMarker Props`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Prop`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#slidermarker-value"
            }}><inlineCode parentName="a">{`value`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`true`}</td>
        </tr>
      </tbody>
    </table>
    <h5 {...{
      "id": "slidermarker-value"
    }}>{`SliderMarker value`}</h5>
    <p><inlineCode parentName="p">{`value: number`}</inlineCode></p>
    <p>{`The value to denote where the marker should appear along the track.`}</p>
    <h3 {...{
      "id": "sliderhandle"
    }}>{`SliderHandle`}</h3>
    <p>{`The handle that the user drags along the track to set the slider value.`}</p>
    <h4 {...{
      "id": "sliderhandle-css-selectors"
    }}>{`SliderHandle CSS Selectors`}</h4>
    <p>{`Please see the `}<a parentName="p" {...{
        "href": "/styling"
      }}>{`styling guide`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`[data-reach-slider-handle] {
}
[data-reach-slider-handle][aria-disabled] {
}
[data-reach-slider-handle][aria-orientation="(horizontal|vertical)"] {
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      